<template>
  <div
    class="gis-container position-relative"
    :class="zoom >= 15 ? 'show-geo-label' : 'hide-geo-label'"
  >
    <div
      class="search-wrapper d-flex position-absolute bg-white rounded-end shadow-sm"
    >
      <div v-if="!isSearchCollapse" class="ps-3">
        <div class="p-3 pb-2">
          <div class="mb-3 d-flex">
            <div class="border rounded-3 overflow-hidden">
              <input
                v-model="searchType"
                class="d-none"
                id="cadastral"
                type="radio"
                name="search-type"
                value="cadastral"
              />
              <label role="button" class="px-3 py-2" for="cadastral">
                地籍
              </label>
              <input
                v-model="searchType"
                class="d-none"
                id="address"
                type="radio"
                name="search-type"
                value="address"
              />
              <label role="button" class="px-3 py-2" for="address">地址</label>
            </div>
          </div>
          <div class="d-flex flex-wrap">
            <template v-if="searchType === 'cadastral'">
              <div>
                <select
                  v-model="city"
                  class="border form-select py-3 ps-4 fs-14 text-elite-blue"
                  @change="handleChangeCity"
                >
                  <option value="" hidden>鄉鎮市</option>
                  <option
                    v-for="town in towns"
                    :key="town.towncode"
                    :value="town.towncode"
                  >
                    {{ town.townname }}
                  </option>
                </select>
                <div
                  class="text-danger mt-1 ms-2"
                  :class="isValidating && !city ? '' : 'opacity-0'"
                >
                  必填
                </div>
              </div>
              <div>
                <select
                  v-model="selectedStreet"
                  class="border form-select py-3 ps-4 fs-14 text-elite-blue"
                >
                  <option value="" hidden>段名</option>
                  <option
                    v-for="street in streets"
                    :key="street.sectcode"
                    :value="street.sectcode"
                  >
                    {{ street.sectstr }}
                  </option>
                </select>
                <div
                  v-if="isValidating && !selectedStreet"
                  class="text-danger mt-1 ms-2"
                >
                  必填
                </div>
              </div>
            </template>
            <div class="position-relative">
              <div class="d-flex flex-fill">
                <input
                  v-model="searchValue"
                  class="border rounded-start form-input py-3 ps-4 flex-fill w-auto"
                  :class="[searchType === 'address' && 'form-input-address']"
                  type="text"
                  :placeholder="
                    searchType === 'cadastral'
                      ? '地號範例：05160000'
                      : '縣府路 100 號'
                  "
                />
                <div
                  class="text-white bg-cobalt py-3 px-5 text-nowrap rounded-end"
                  role="button"
                  @click="handleClick"
                >
                  定位
                </div>
              </div>
              <div class="error-message-wrapper d-flex">
                <div
                  v-if="isValidating && !searchValue"
                  class="text-danger mt-1 ms-2"
                >
                  必填
                </div>
                <div v-else-if="isWrong" class="text-danger mt-1 ms-2">
                  {{ searchType === 'cadastral' ? '查無地號' : '查無地址' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center btn-collapse px-3 border-start fw-bold flex-column justify-content-center"
        role="button"
        @click="isSearchCollapse = !isSearchCollapse"
      >
        <template v-if="!isSearchCollapse">
          <span>收</span>
          <span>合</span>
          <i class="fas fa-chevron-left mt-1"></i>
        </template>
        <template v-else>
          <span>展</span>
          <span>開</span>
          <i class="fas fa-chevron-right mt-1"></i>
        </template>
      </div>
    </div>
    <div
      class="layer-wrapper position-absolute shadow-sm"
      :class="[isMenuCollapse && 'is-collapse']"
    >
      <div
        class="p-3 bg-cobalt text-white d-flex align-items-center fs-18"
        role="button"
        @click="isMenuCollapse = !isMenuCollapse"
      >
        <template v-if="!isMenuCollapse">
          關閉選單
          <i class="fas fa-times ms-auto"></i>
        </template>
        <template v-else>
          選單
          <i class="fas fa-plus ms-2"></i>
        </template>
      </div>
      <div v-show="!isMenuCollapse">
        <div
          class="p-3 bg-light d-flex align-items-center fs-18"
          role="button"
          @click="isLayerCollapse = !isLayerCollapse"
        >
          底圖設定
          <i v-if="!isLayerCollapse" class="fas fa-chevron-up ms-auto"></i>
          <i v-else class="fas fa-chevron-down ms-auto"></i>
        </div>
        <div v-if="!isLayerCollapse" class="bg-white py-1">
          <div v-for="item in layers" :key="item" class="py-1 ms-4">
            <input
              v-model="layer"
              :id="item.value"
              type="radio"
              class="form-check-input"
              :value="item.value"
            />
            <label class="ms-2" :for="item.value" role="button">
              {{ item.name }}
            </label>
          </div>
        </div>
        <div
          class="p-3 bg-light d-flex align-items-center fs-18"
          role="button"
          @click="isMapDataCollapse = !isMapDataCollapse"
        >
          圖層切換
          <i v-if="!isMapDataCollapse" class="fas fa-chevron-up ms-auto"></i>
          <i v-else class="fas fa-chevron-down ms-auto"></i>
        </div>
        <div v-if="!isMapDataCollapse" class="bg-white py-1">
          <div v-for="item in overlays" :key="item.value" class="py-1 ms-4">
            <input
              v-model="mapData"
              :id="item.value"
              type="checkbox"
              class="form-check-input"
              :value="item.value"
            />
            <label class="ms-2 lh-16" :for="item.value" role="button">
              {{ item.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <l-map
      id="map"
      ref="map"
      v-model:zoom="zoom"
      v-model:center="center"
      class="h-100 w-100"
      :maxZoom="20"
      :minZoom="5"
      :maxBounds="[
        [24.289, 120.619],
        [24.743, 121.266],
      ]"
    >
      <l-marker v-if="markerLocation.length" :lat-lng="markerLocation" />
      <template v-for="item in layers" :key="item.value">
        <l-wms-tile-layer
          v-if="item.value === layer"
          :base-url="item.value"
          layer-type="base"
          version="1.1.1"
          format="image/png"
          transparent="true"
          :uppercase="true"
          layers="0"
        />
      </template>
      <template v-for="item in mapData" :key="item.value">
        <l-tile-layer
          v-if="item === 'dmap'"
          layer-type="overlay"
          url="https://landmaps.nlsc.gov.tw/S_Maps/wmts/DMAPS/default/EPSG:3857/{z}/{y}/{x}"
          attribution="國土測繪中心"
        />
        <template
          v-else-if="item === 'industry-geos'"
          :geojson="industryGeoJson"
        >
          <l-geo-json
            v-for="json in industryGeoJson.features"
            :key="json"
            :geojson="json"
            @add="handleAddIndustry"
          ></l-geo-json>
        </template>
        <template v-else-if="item === 'development-geos'">
          <l-geo-json
            v-for="json in developmentGeoJson.features"
            :key="json"
            :geojson="json"
            @add="handleAddDevelopment"
          />
        </template>
        <l-wms-tile-layer
          v-else
          :base-url="item"
          layer-type="overlay"
          version="1.1.1"
          format="image/png"
          transparent="true"
          layers="0"
          :uppercase="true"
        />
      </template>
    </l-map>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'
  import 'leaflet/dist/leaflet.css'
  import L from 'leaflet'
  import fxparser from 'fast-xml-parser'

  import {
    textQueryMap,
    getListAndSection,
    getLandPositionLongitudeLatitude,
    getIndustryGeoJson,
    getDevelopmentGeoJson,
  } from '@/service'
  import config from '@/config'
  import {
    LMap,
    LWmsTileLayer,
    LTileLayer,
    LGeoJson,
    LMarker,
  } from '@vue-leaflet/vue-leaflet'

  const xmlParser = new fxparser.XMLParser()

  export default defineComponent({
    name: 'Gis',
    components: {
      LMap,
      LWmsTileLayer,
      LTileLayer,
      LGeoJson,
      LMarker,
    },
    async created() {
      await getIndustryGeoJson().then(({ data }) => {
        this.industryGeoJson = data
      })

      await getDevelopmentGeoJson().then(({ data }) => {
        this.developmentGeoJson = data
      })
    },
    data() {
      const { overlays, layers, towns } = config

      return {
        markerLocation: [],
        streets: [],
        layers,
        overlays: overlays.filter((x) => x.value),
        layer: layers[0].value,
        mapData: [],
        isSearchCollapse: false,
        isLayerCollapse: false,
        isMapDataCollapse: false,
        isMenuCollapse: false,
        city: '',
        selectedStreet: '',
        searchType: 'cadastral',
        cadastralValue: '',
        addressValue: '',
        searchValue: '',
        center: [24.570109, 120.822429],
        zoom: 15,
        url: 'https://wms.nlsc.gov.tw/wms?LAYERS=EMAP2',
        towns,
        industryGeoJson: null,
        developmentGeoJson: null,
        isWrong: false,
        isValidating: false,
      }
    },
    watch: {
      layer() {
        const temp = this.mapData
        this.mapData = []
        this.$nextTick(() => {
          this.mapData = temp
        })
      },
      searchType() {
        this.isWrong = false
        this.isValidating = false

        if (this.searchType === 'cadastral') {
          this.searchValue = this.cadastralValue
        } else {
          this.searchValue = this.addressValue
        }
      },
      searchValue() {
        this.isWrong = false

        if (this.searchType === 'cadastral') {
          this.cadastralValue = this.searchValue
        } else {
          this.addressValue = this.searchValue
        }
      },
      selectedStreet() {
        this.isWrong = false
      },
      city() {
        this.isWrong = false
      },
    },
    methods: {
      addLabel(event, getTextOfNodes) {
        const layer = event.target
        const bounds = layer.getBounds()
        const center = bounds.getCenter()
        const text = getTextOfNodes(layer)
        const label = L.divIcon({
          className: 'label',
          html: `
            <span class="position-absolute" style="transform: translateX(-50%);">${text}</span>
          `,
        })
        L.marker(center, { icon: label }).addTo(layer)
      },
      handleAddIndustry(event) {
        this.addLabel(event, (layer) => {
          const { properties } = layer.options.geojson
          return properties.industrial_district
        })
      },
      handleAddDevelopment(event) {
        this.addLabel(event, (layer) => {
          const { properties } = layer.options.geojson
          return properties.name
        })
      },
      handleChangeCity() {
        getListAndSection(this.city).then((res) => {
          this.streets = xmlParser
            .parse(res.data)
            .sectItems.sectItem.map((item) => ({
              ...item,
              sectcode:
                item.sectcode < 1000 ? `0${item.sectcode}` : item.sectcode,
            }))
          this.selectedStreet = ''
        })
      },
      handleClick() {
        this.isWrong = false
        this.isValidating = true

        if (this.searchType === 'cadastral') {
          if (
            !this.selectedStreet ||
            !this.searchValue ||
            !this.searchValue.length
          ) {
            // this.isWrong = true
            return
          }

          getLandPositionLongitudeLatitude(
            this.selectedStreet,
            this.searchValue
          )
            .then((res) => {
              const { cadsPositionItem } = xmlParser.parse(res.data)

              if (cadsPositionItem.error) {
                this.isWrong = true
              } else {
                const { repX, repY } = cadsPositionItem
                this.center = [Number(repY), Number(repX)]
                this.markerLocation = [Number(repY), Number(repX)]
                this.zoom = 18
              }
            })
            .catch(() => {
              this.isWrong = true
            })
        } else {
          textQueryMap(this.searchValue)
            .then((res) => {
              const { addressItems } = xmlParser.parse(res.data)

              if (addressItems && addressItems.addressItem) {
                const { location } = addressItems.addressItem
                const [lng, lat] = location.split(',')
                this.center = [Number(lat), Number(lng)]
                this.markerLocation = [Number(lat), Number(lng)]
                this.zoom = 18
              } else {
                this.isWrong = true
              }
            })
            .catch(() => {
              this.isWrong = true
            })
        }
      },
    },
  })
</script>

<style lang="scss">
  .hide-geo-label {
    .label {
      display: none !important;
    }
  }

  .show-geo-label {
    .label {
      width: 8rem !important;
      font-weight: 500;
      font-size: 16px;
      color: #1042a4;
    }
  }
</style>

<style lang="scss" scoped>
  .search-wrapper {
    top: 1rem;
    left: 0;
    z-index: 10001;
    min-height: 150px;

    .form-select {
      width: 200px;
      cursor: pointer;
    }

    .form-input {
      min-width: 200px;

      &-address {
        min-width: 300px;
      }
    }
  }

  .layer-wrapper {
    top: 1rem;
    right: 0;
    z-index: 10000;
    width: 20rem;

    &.is-collapse {
      width: 6rem;
    }
  }

  [name='search-type']:checked + label {
    background: #1042a4;
    color: #fff;
  }

  .btn-collapse {
    width: 3rem;
  }

  .error-message-wrapper {
    height: 26px;
  }

  @media screen and (min-width: 768px) {
    .flex-wrap {
      gap: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    select {
      margin-right: 1rem;
    }
  }
</style>
